<template>
  <div class="report-tx-info__wrapper">
    <SectionBlock class="mb-4">
      <template #title>
        Transaction Information
      </template>
      <template #body>
        <div class="report-tx-info__body-wrapper">
          <div class="report-tx-info__body-top">
            <InfoBlock
              ellipsis-value
              label="Transaction Hash"
              :loading="loading"
              :value="info.tx_hash"
              with-copy
            />
            <GeneralInfo
              :info="info"
              :loading="loading"
            />
          </div>
          <div
            class="report-tx-info__body-middle"
            :class="{
              'report-tx-info__body-middle-split': hasAdditionalInfo,
            }"
          >
            <InfoBlock
              label="Total Amount"
              :loading="loading"
              :rate="info.price"
              rate-time="historical"
              :rate-timestamp="info.priceTimestamp"
              :value="info.formattedTotalAmount"
            />
            <InfoBlock
              label="Block"
              :loading="loading"
              :value="info.blockHeight"
            />
            <InfoBlock
              v-if="hasAdditionalInfo"
              label="Total Inputs"
              :loading="loading"
              :value="info.totalInputs"
            />
            <InfoBlock
              v-if="hasAdditionalInfo"
              label="Total Outputs"
              :loading="loading"
              :value="info.totalOutputs"
            />
            <InfoBlock
              label="Timestamp"
              :loading="loading"
              :value="
                info.timestamp
                  ? formatDate(info.timestamp * 1000, 'dd.MM.yyyy HH:mm')
                  : null
              "
            />
          </div>
        </div>
      </template>
    </SectionBlock>
  </div>
</template>

<script>
// Components
import SectionBlock from '@/components/gl-section-block'
import InfoBlock from '@/pages/report/components/info/InfoBlock'
import GeneralInfo from '@/pages/report/components/info/GeneralInfo'
// utils
import { formatDate } from '@/utils/format-date'

export default {
  name: 'TxInfoWrapper',
  components: {
    SectionBlock,
    InfoBlock,
    GeneralInfo,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasAdditionalInfo: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    formatDate,
  },
}
</script>
<style>
.report-tx-info__body-wrapper {
  margin-bottom: 24px;
}
.report-tx-info__body-top {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--Color-Unknown-tag);
}
.report-tx-info__body-middle {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

@media (max-width: 1200px) {
  .report-tx-info__body-middle {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .report-tx-info__body-middle {
    grid-template-columns: 1fr;
  }
}
</style>
