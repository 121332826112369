<template>
  <div>
    <TopTxActions
      v-if="!essential"
      class="mb-4"
      :loading="txDataLoading"
    />
    <TxInfoWrapper
      :has-additional-info="txInfo2.coinFamily == 'btc'"
      :info="txInfo2"
      :loading="txDataLoading"
    />
    <BottomInfoActions
      v-if="!essential"
      class="mb-4"
      :download-loading="downloadLoading"
      :enable-download-items="enableDownloadItems"
      :info="txInfo2"
      :loading="txDataLoading"
      report-type="tx"
      @export-report-to-csv="$emit('export-report-to-csv')"
      @export-to-pdf="$emit('export-to-pdf')"
    />

    <SectionBlock
      body-white
      class="mb-4"
    >
      <template #title>
        GL-Score
      </template>
      <template #body>
        <ScoreChartWrapper
          :incoming-sources="allDataSource.incoming"
          :loading="calculationLoading"
          :outgoing-sources="allDataSource.outgoing"
          :score="totalFunds"
        />
      </template>
    </SectionBlock>

    <SectionBlock
      v-if="showBarsSection"
      body-white
      class="mb-4"
    >
      <template #title>
        Risk Exposure
      </template>
      <template #body>
        <ReportBarsWrapper
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :essential="essential"
          :loading="calculationLoading"
          :pure-sources="pureSources"
          :selected-token="selectedToken"
        />
      </template>
    </SectionBlock>

    <ReportTablesWrapper
      :currencies-by-direction="currenciesByDirection"
      :essential="essential"
      :loading="calculationLoading"
      :pure-sources="pureSources"
      report-type="tx"
      :selected-token="selectedToken"
    />
  </div>
</template>

<script>
// Components
import SectionBlock from '@/components/gl-section-block'
import ReportBarsWrapper from '@/pages/report/components/bars/ReportBarsWrapper'
import ReportTablesWrapper from '@/pages/report/components/table/ReportTablesWrapper'
import ScoreChartWrapper from '@/pages/report/components/chart/ScoreChartWrapper'
import TxInfoWrapper from '@/pages/report/components/info/TxInfoWrapper'
import TopTxActions from '@/pages/report/components/info/TopTxActions'
import BottomInfoActions from '@/pages/report/components/info/BottomInfoActions'
// Vuex
import { mapState } from 'vuex'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

import appConfig from '@/utils/appConfig'

export default {
  components: {
    ReportBarsWrapper,
    ReportTablesWrapper,
    SectionBlock,
    ScoreChartWrapper,
    TxInfoWrapper,
    TopTxActions,
    BottomInfoActions,
  },
  mixins: [deviceWidthMixin],
  props: {
    sourceConfigsByDirection: {
      type: Object,
      default: () => ({}),
    },
    txInfo2: {
      type: Object,
      default: () => ({}),
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
    allDataSource: {
      type: [Array, Object],
      default: () => [],
    },
    pureSources: {
      type: Object,
      default: () => ({}),
    },
    currenciesByDirection: {
      type: Object,
      default: () => ({}),
    },
    allDataSourceByOwner: {
      type: [Array, Object],
      default: () => [],
    },
    txDataLoading: {
      type: Boolean,
      default: false,
    },
    calculationLoading: {
      type: Boolean,
      default: false,
    },
    totalFunds: {
      type: [Number, String],
      default: '',
    },
    downloadLoading: {
      type: Boolean,
      default: false,
    },
    enableDownloadItems: {
      type: Array,
      Object,
      default: () => [],
    },
    essential: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      RISK_POINT: 55,
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinData']),
    showBarsSection() {
      return Boolean(
        this.calculationLoading ||
          this.pureSources?.incoming?.length ||
          this.pureSources?.outgoing?.length,
      )
    },
  },
  methods: {
    explore(tx) {
      const { href } = this.$router.resolve({
        name: 'analytics',
        query: { tx, type: this.coinData.key },
      })
      window.open(href, '_blank')
    },
  },
}
</script>
